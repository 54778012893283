/*================================================
Home CSS
=================================================*/
.home-banner-bg{
  background-image: url('../src/assets/images/solar-test.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 92vh;
    width: 100%;
}
/*================================================
Equipment CSS
=================================================*/
.equip-banner-bg{
    background-image: url('../src/assets/images/Equipment-Banner.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 50vh;
    display: flex;
    align-items: center;
}
.banner-sub-pages-head{
    color: white;
    font-size: 60px;
    font-weight: 700;
    letter-spacing: 0.5px;
    animation: fadeInLeft 2s ease-in-out;
}
.banner-sub-pages-text{
  color: white;
  font-size: 20px;
  margin-top: 20px;
  letter-spacing: 0.5px;
}
.equ-two-tier-sec{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.equ-two-tier-card{
    width:49%;
}
.blue-bg-sub-card{
    background-color: #2065C5;
    padding:90px;
    height:100%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.blue-bg-sub-card p{
    color: #6CA8F8;
    font-size:16px;
    font-weight: 400;
    letter-spacing: 0.5px;
    line-height: 27px;
    margin-bottom: 0px;
}
.blue-bg-sub-card h2{
  color: white;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 60px;
}
.equ-mini-center-text{
    text-align: center;
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 0.5px;
    padding-bottom: 50px;
}

/*================================================
Logistics CSS
=================================================*/
.Logistics-banner-bg{
    background-image: url('../src/assets/images/Logistics-Storage-Banner.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 67vh;
    display: flex;
    align-items: center;
}
/*================================================
Finance CSS
=================================================*/
.finance-banner-bg{
    background-image: url('../src/assets/images/Finance-Banner.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    align-items: center;
}
.finance-banner-bg ul li::marker {
  color: white;
}
/*================================================
About CSS
=================================================*/
.about-banner-bg{
    background-image: url('../src/assets/images/Aboutus-Banner.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 50vh;
    display: flex;
    align-items: center;
}
.about-two-tier-sec{
    display: flex;
    justify-content: space-between;
}

/*================================================
contact CSS
=================================================*/
.contact-banner-bg{
    background-image: url('../src/assets/images/Contactus-Banner.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 67vh;
    display: flex;
    align-items: center;
}



  /*=========== Responsive view ==========*/
@media screen and (min-width: 1201px) and (max-width: 1400px){
  .banner-head{
    font-size: 40px;
  }
  .banner-text {
    font-size: 16px;
    line-height: 30px;
  }
  .banner-btn{
    font-size: 14px;
  }
  .banner-sub-pages-head{
    font-size: 40px;
  }
  .sub-text {
    font-size: 16px;
    line-height: 30px;
  }
  .equ-mini-center-text{
    font-size: 30px;
  }
  .three-tier-card h3{
    font-size: 20px;
  }
  .Navbar-logo {
    height: 20px;
  }
  .Navbar-buttons-align li a{
    font-size: 14px;
    margin-left: 20px;
  }
  .black-link{
    margin-left: 20px;
  }
  .black-sub-head{
    font-size: 30px;
  }
  .finance-button {
    font-size: 16px;
    padding: 9px 15px;
  }
  .blue-sub-head{
    font-size: 30px;
  }
  .about-three-tier-card-text-align h3{
    font-size: 25px;
  }
  .about-btn-align a{
    font-size: 16px;
  }
  .about-three-tier-card-text-align p{
    min-height: 70px;
  }
  .mini-center-text{
    font-size: 30px;
    padding-bottom: 30px;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1378px){
  .about-three-tier-card-text-align p{
    min-height: 90px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1200px){
    .banner-sub-pages-head {
      font-size: 40px;
    }
    /* .banner-align{
      top: 18%;
    } */
    .banner-text{
      font-size: 18px;
    }
    .banner-head{
      font-size: 40px;
    }
    .blue-bg-sub-card h2{
      font-size: 35px;
      line-height: 45px;
    }
    .blue-bg-sub-card {
      padding: 50px;
    }
    .black-sub-head {
      font-size: 30px;
    }
    .sub-text{
      font-size: 16px;
      line-height: 30px;
    }
    .about-btn-align a{
      font-size: 16px;
    }
    .about-three-tier-card-text-align h3{
      font-size: 25px;
    }
    .white-sub-head {
      font-size: 35px;
    }
    .equ-mini-center-text {
      font-size: 30px;
    }
    .three-tier-card h3{
      font-size: 20px;
    }
    .three-tier-card img{
      width: 100%;
      height: 200px;
     }
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .blue-bg-sub-card {
      padding: 30px;
    }
    .blue-bg-sub-card h2 {
      font-size: 30px;
      font-weight: 500;
      line-height: 45px;
    }
    .banner-sub-pages-head{
        font-size: 50px;
    }
    .contact-card1 {
        width: 100%;
    }
    .contact-card2 {
        width: 100%;
    }
    .black-sub-head{
      font-size: 30px;
    }
    .sub-text{
      font-size: 16px;
      line-height: 30px;
    }
    .about-two-tier-sec {
      flex-direction: column;
    }
    .equ-two-tier-card {
      width: 100%;
    }
    .equ-two-tier-card-align{
      padding:30px 0px 0px 0px;
    }
    .about-three-tier-section{
      flex-wrap: wrap;
    }
    .about-three-tier-card{
      width: 49%;
      margin:0px 0px 15px 0px;
    }
    .finance-two-tier-section{
      flex-direction: column;
    }
    .finance-section-card {
      width: 100%;
    }
    .finance-section-cement-card{
      bottom: 0px;
      margin: 30px 0px;
    }
    .white-sub-head{
      font-size: 30px;
    }
    .equ-two-tier-sec{
      flex-direction: column;
    }
    .equ-mini-center-text {
      font-size: 30px;
    }
    .three-tier-section {
      flex-wrap: wrap;
    }
    .three-tier-card{
      width: 49%;
      margin-bottom: 20px;
    }
    .three-tier-card h3{
      font-size: 20px;
    }
    /* .banner-align{
      top: 18%;
    } */
    .banner-text{
      font-size: 18px;
    }
    .banner-head{
      font-size: 40px;
    }
  }
  @media screen and (min-width: 320px) and (max-width: 768px){
    .Navbar-logo {
      height: 20px;
    }
    .banner-sub-pages-head {
      font-size: 30px;
    }
    .contact-two-tier-section{
        flex-direction: column;
    }
    .contact-card1 {
        width: 100%;
    }
    .contact-card2 {
        width: 100%;
    }
    .field-rows-align{
        flex-direction: column;
    }
    .contact-fields-align {
        width: 100%;
    }
    .blue-bg-sub-card{
        padding: 50px;
    }
    .blue-bg-sub-card h2{
        font-size: 30px;
        line-height: 45px;
    }
    .about-two-tier-sec {
      flex-direction: column;
    }
    .equ-two-tier-card {
      width: 100%;
    }
    .equ-two-tier-card-align{
      padding:30px 0px 0px 0px;
    }
    .about-three-tier-section{
      flex-wrap: wrap;
    }
    .about-three-tier-card{
      width: 100%;
      margin:0px 0px 15px 0px;
    }
    .black-sub-head {
      font-size: 25px;
    }
    .sub-text{
      font-size: 16px;
      line-height: 30px;
    } .finance-two-tier-section{
      flex-direction: column;
    }
    .finance-section-card {
      width: 100%;
    }
    .finance-section-cement-card{
      bottom: 0px;
      margin: 30px 0px;
      width: 300px;
    }
    .white-sub-head{
      font-size: 30px;
    }
    .two-tier-section{
      flex-direction: column;
    }
    .two-tier-card1 {
      width: 100%;
      margin: 15px 0px;
    }
    .two-tier-card2 {
      width: 100%;
      margin: 15px 0px;
    }
    .equ-two-tier-sec{
      flex-direction: column;
    }
    .equ-mini-center-text {
      font-size: 25px;
    }
    .three-tier-section {
      flex-wrap: wrap;
    }
    .three-tier-card{
      width: 100%;
      margin-bottom: 20px;
    }
    .three-tier-card h3{
      font-size: 20px;
    }
    .finance-section-card2 {
      width: 100%;
    }
    /* .banner-align{
      top: 8%;
    } */
    .banner-text{
      font-size: 15px;
      line-height: 27px;
      width: 90%;
    }
    .banner-head{
      font-size: 40px;
    }
    .mini-center-text{
      padding-top: 0px;
    }
    .pad-tp-bt-140 {
      padding: 60px 0px 60px 0px;
    }
    .logistics-align{
      flex-direction: column-reverse;
    }
    .finance-sub-text{
      font-size: 16px;
      margin-bottom: 0px;
    }
    .apply-btn-align{
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .finance-button{
      font-size: 16px;
      padding:9px 15px;
    }
    .contact-btn{
      width: 30%;
    }
    .banner-btn{
      font-size: 16px;
    }
    .blue-sub-head{
      font-size: 30px;
    }
    .mini-center-text{
      font-size: 25px;
    }
    .about-three-tier-card-text-align h3 {
      font-size: 25px;
    }
    .about-three-tier-card-text-align p{
      min-height: auto;
    }
    .about-btn-align a{
      font-size: 16px;
    }
    
  }
  @media screen and (max-width: 992px){
    .mobile-hide{
      display: none;
    }
  }
  /*================================================
Scroll To Top CSS
=================================================*/
button.scroll-to-top {
  position: fixed;
  bottom: 50px;
  right: 40px;
  z-index: 99;
  border: none;
  padding: 7px 5px;
  border-radius: 5px;
  transition: .3s;
  background: #2065c5;
}
button.scroll-to-top svg{
  width: 20px;
}
button.scroll-to-top  path {
  fill: white ;
  stroke-width: 2px; 
}