body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: "Roboto", sans-serif !important;
}

code {
  font-family: "Roboto", sans-serif !important;
}
.home-about-align{
  padding:50px 0px 0px 0px;
}
.pad-tp-bt-140 {
  padding: 50px 0px 80px 0px;
}
.pad-tp-50 {
  padding-top: 30px;
}
.pad-tp-140 {
  padding-top: 30px;
}
.pad-tp-bt-100 {
  padding: 30px 0px 80px 0px;
}
.pad-tp140-bt140{
  padding: 30px 0px 80px 0px;
}
.pad-tp-bt-20 {
  padding: 20px 0px;
}
.pad-tp-30 {
  padding-top: 30px;
}
.mar-bt-0{
  margin-bottom: 0px;
}
.blue-clr {
  color: #2065c5;
}
.white-clr {
  color: white;
}
.blue-bg {
  background-color: #2065c5;
}
.cement-bg {
  background-color: white;
}
.center-text {
  text-align: center;
}
.black-clr {
  color: black;
}
.hide-overflow {
  overflow: hidden;
}
/*================================================
Navbar CSS
=================================================*/

.nav-bg {
  background-color: #fff;
  box-shadow: 0 3px 15px #b3b3b373;
  position: fixed;
  width: 100%;
  z-index: 99;
}

.Navbar-align {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 70px;
  padding: 15px 0;
}

.Navbar-logo {
  height: 25px;
}

.Navbar-buttons-align {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  padding-left: 0px;
}

.Navbar-buttons-align li {
  list-style: none;
}

.Navbar-buttons-align li a {
  text-decoration: none;
  margin-left: 40px;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.5px;
  color: black;
}
.Navbar-buttons-align li a:hover {
  color: #2065c5;
}

.Navbar-buttons-align li a.active {
  color: #2065c5; /* Your active color */
  padding-bottom: 24px;
  border-bottom: 4px solid #000;
  /* Other styles for an active link */
}
.black-link {
  background: #2065c5;
  padding: 9px 30px;
  position: relative;
  text-align: center;
  margin-left: 40px;
  border-radius: 5px;
  border: none;
}
.black-link a {
  color: white !important;
  padding: 0px;
  margin-left: 0px !important;
}

.slide-layer {
  position: absolute;
  background-color: #ffffff;
  width: 0px;
  height: 100%;
  opacity: 0.2;
  top: 0px;
  left: 0px;
  transition: 2s;
}
.black-link:hover .slide-layer {
  width: 120px;
}
.footer-bg {
  background-color: #032c65;
}
.footer-bg p {
  color: white;
  letter-spacing: 0.5px;
  margin-bottom: 0px;
}
.pad-tb-bt-30 {
  padding: 30px 0px;
}
.Navbar-toggle {
  display: none;
}
.web-menu {
  display: flex;
}
.mobile-menu {
  display: none;
}
.Navbar-close-btn {
  position:absolute;
  top:25px;
  right:25px;
  color: white;
  background: none;
  border: none;
  font-size: 25px;
}
/*=========== Navbar Responsive view ==========*/
@media screen and (max-width: 1024px) {
  .Navbar-toggle {
    display: block;
  }
  .web-menu {
    display: none !important;
  }
  .mobile-menu {
    display: block;
    position: absolute;
    top: 0%;
    left: 0%;
    background: #212326;
    width: 100%;
  }
  .mobile-menu-align {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Navbar-buttons-align {
    display: block;
  }
  .Navbar-buttons-align li {
    padding: 10px 0px;
  }
  .Navbar-buttons-align li a {
    color: white;
    font-weight: 500;
    margin-left: 0px;
  }
  .black-link {
    margin-left: 0px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1200px){
  .Navbar-logo{
    height: 20px;
  }
  .Navbar-buttons-align li a{
    font-size: 12px;
    margin-left: 20px;
  }
  .black-link{
    margin-left: 20px;
    padding: 9px 20px;
  }
}

/*================================================
Banner CSS
=================================================*/
.carousel-caption {
  bottom: 16.25rem !important;
  left: 26% !important;
  width: 45%;
}
.banner-align {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.banner-btn {
  background: #2065c5;
  color: white;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 15px 30px;
  text-decoration: none;
  border-radius: 5px;
  border: none;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  box-shadow: 0 0 10px #00000061;
}
.banner-btn-align {
  animation: fadeInUp 2s ease-in-out;
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(300px);
  }
  to {
    opacity: 1;
  }
}
.banner-btn:hover .slide-layer {
  width: 100%;
}
.banner-head {
  color: white;
  font-size: 60px;
  font-weight: 700;
  animation: fadeInRight 2s ease-in-out;
  letter-spacing: 0.5px;
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(300px);
  }
  to {
    opacity: 1;
  }
}
.banner-text {
  color: white;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  line-height: 41px;
  letter-spacing: 0.5px;
  margin-bottom: 50px;
  animation: fadeInLeft 2s ease-in-out;
  width: 51%;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-300px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
/*================================================
Equipment CSS
=================================================*/
.two-tier-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.two-tier-card1 {
  width: 40%;
}
.two-tier-card2 {
  width: 50%;
}
.blue-sub-head {
  color: #2065c5;
  font-size: 40px;
  font-weight: 700;
  line-height: 2;
}
.sub-text {
  font-size: 20px;
  letter-spacing: 0.5px;
  line-height: 36px;
  color: #82868a;
}
.mini-center-text {
  text-align: center;
  font-size: 35px;
  font-weight: 700;
  letter-spacing: 0.5px;
  padding-top: 50px;
  padding-bottom: 50px;
}
.three-tier-section {
  display: flex;
  justify-content: space-between;
}
.three-tier-card {
  width: 32.5%;
  text-align: center;
}
.three-tier-card img{
 height:200px;
}

.three-tier-card h3 {
  font-size: 24px;
  font-weight: 700;
  padding: 40px 0px 5px 0px;
}
.black-sub-head {
  font-weight: 600;
  font-size: 35px;
  line-height: 40px;
  letter-spacing: 0.5px;
}
.center-button-left {
  text-align: end;
  margin-top: 50px;
}
.finance-section {
  background-color: #2065c5;
  width: 95%;
}
.finance-two-tier-section {
  display: flex;
  justify-content: space-between;
  margin-left: 5%;
}
.finance-section-card {
  width: 49%;
}
.finance-section-card2{
  width: 45%;
}
.finance-section-cement-card {
  background-color: #f5f7f7;
  max-width: 370px;
  padding: 30px 55px 30px 55px;
  position: relative;
  bottom: 60px;
}
.white-sub-head {
  color: white;
  font-size: 50px;
  font-weight: 700;
}
.finance-sub-text {
  color: aliceblue;
  line-height: 30px;
  margin-bottom: 1.8rem;
  font-size: 20px;
  letter-spacing: 0.5px;
}
.finance-section-card2 ul li::marker {
  color: white;
}
.finance-section-card2 ul {
  margin: 30px 0px 0px 0px;
}

.finance-image-align {
  position: relative;
  top: 30%;
}
.finance-button {
  color: #2065c5;
  background: white;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 15px 30px;
  text-decoration: none;
  border-radius: 5px;
  border: none;
  position: relative;
  overflow: hidden;
}
.slide-layer1 {
  position: absolute;
  background-color: black;
  width: 0px;
  height: 100%;
  opacity: 0.2;
  top: 0px;
  left: 0px;
  transition: 2s;
}
.finance-button:hover .slide-layer1 {
  width: 151px;
}
.apply-btn-align {
  margin-top: 50px;
  margin-bottom: 80px;
  display: flex;
  align-items: center;
}
.about-three-tier-section {
  display: flex;
  justify-content: space-between;
}
.about-three-tier-card {
  background: white;
  width: 32.5%;
  box-shadow: 0 0 10px #00000030;
}
.about-three-tier-card-text-align {
  margin: 30px 50px 50px 50px;
}
.about-three-tier-card-text-align p{
  min-height: 110px;
}
.about-three-tier-card-text-align h3 {
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.5px;
}
.about-btn-align {
  display: flex;
  align-items: center;
  width: fit-content;
  overflow: hidden;
}
.about-btn-align a {
  color: #2065c5;
  font-size: 18px;
  text-decoration: none;
  letter-spacing: 0.5px;
  font-weight: 600;
  border: 2px solid #2065c5;
  padding: 9px 15px;
  position: relative;
}
.home-about-banner {
  background-image: url("../src/assets/images/Home-about-banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 92vh;
  width: 100%;
  position: relative;
}
.home-logistics-banner {
  background-image: url("../src/assets/images/Home-logistics-banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 92vh;
  width: 100%;
  position: relative;
}
.about-btn-align:hover .slide-layer1 {
  width: 144px;
}
.contact-bg {
  background-image: url("../src/assets/images/contact.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.contact-two-tier-section {
  display: flex;
  justify-content: space-between;
}
.contact-card1 {
  width: 40%;
}
.contact-card2 {
  width: 100%;
}
.fields-align {
  width: 100%;
  padding: 10px 8px;
  margin-bottom: 30px;
  border-color: #00000030;
  border-radius: 4px;
  font-size: 16px;
  color: #000;
}
.form-align {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.field-radio-align {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  margin-bottom: 30px;
  width: 100%;
}
.contact-btn {
  background: transparent;
  border: 2px solid #2065c5;
  color: #2065c5;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 9px 15px;
  text-decoration: none;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  width: 20%;
}
.contact-btn:hover .slide-layer1 {
  width: 100%;
}
.field-rows-align {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.multi-line-field-align {
  width: 100%;
  padding: 8px;
  margin-bottom: 30px;
  border-color: #00000030;
  border-radius: 4px;
  font-size: 16px;
  color: #000;
}
.contact-fields-align {
  width: 49%;
}
.error-message {
  color: red;
}
input:focus-visible {
  outline-style: none;
  border-bottom: 2px solid #2065c5;
}
textarea:focus-visible {
  outline-style: none;
  border-bottom: 2px solid #2065c5;
}
.success-message {
  text-align: center;
  color: green;
  font-size: 30px;
  font-weight: 700;
  margin-top: 9%;
}

